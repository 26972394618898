var vipProtocol = '<p>第1条 服务条款的接受与修改</p>\
                    <p>1.1 本协议是VIP会员与steambig（以下简称为：本平台）之间关于用户成为VIP 会员且使用本平台提供的VIP会员服务所订立的协议以下简称为：本协议）。本协议描述本平台与VIP会员之间关于软件许可以及服务使用及相关方面的权利义务。“VIP会员”或“您”是指完成了成为本平台VIP会员的全部程序（包括会员费用的支付、同意本协议全部内容等），且在遵守本平台相关使用规则下使用本平台提供的VIP会员服务的自然人。请用户务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，如用户在下单时点击接受本协议，即视为用户已阅读并同 意本协议的约束。</p>\
                    <p>1.2 本服务协议构成您使用本平台所提供的VIP会员服务之先决条件，本平台已经以字体加 粗或其他合理方式提示您重点阅读协议中相关免除或限制责任条款，双方确认前述条款不属 于《合同法》第40条规定的“免除其责任、加重对方责任、排除对方主要权利”条款，用户和本平台均认可其合法性及有效性。除非您接受本协议全部条款，否则您无权使用本协议的相关服务。</p>\
                    <p>1.3 您的使用行为（包括但不限于点击同意、进行下一步操作、支付行为等）将视为同意接受本协议及《本平台用户网络服务使用协议》和《本平台隐私政策》各项条款及相关子协议、 页面说明或规范流程等全部内容的约束。</p>\
                    <p>1.4 本平台有权根据业务经营情况不定时对服务条款进行修改，一旦服务条款发生变更和修 改，本平台将在相关页面上进行公告，且修改内容在公告之日的10日后生效和执行；如果 VIP会员不同意本协议的任一修改，可以取消已经获取的VIP会员服务并停止使用；如果VIP 会员继续使用本平台提供的VIP会员服务，则视为VIP会员已经接受本协议的全部修改。</p>\
                    <p>1.5 请您仔细审阅本协议的全部内容并选择是否同意本协议。未成年人应在法定监护人陪同 下审阅和履行，未成年人购买VIP会员服务、行使和履行本协议项下的权利和义务视 为已获得了法定监护人的认可。您在享受VIP会员服务时必须完全、严格遵守本服务 协议条款。</p>\
                    <p>第2条 服务说明</p>\
                    <p>2.1 本平台VIP会员服务涉及的本平台产品/服务的所有权以及相关软件知识产权归本平台所 有，本平台授予VIP会员一项个人的、非独家的、不可转让、可撤销的、有期限的使用许可， 即VIP会员仅享有以上产品/服务和软件的有限使用权。本平台所提供的服务将按照其服务条 款和操作规则严格执行。</p>\
                    <p>2.2 您应对自身在使用本平台所提供的服务时的一切行为（无论是否故意）负全部责任。本平台享有对VIP会员在本平台或/及其关联公司提供的服务平台上的一切活动的监督、提示、检 查的权利，如VIP会员的行为违反有关法律法规或违反本协议条款的约定，本平台享有要求 其纠正、取消会员资格及追究其相关责任等权利。</p>\
                    <p>2.3 本平台向VIP会员提供的全部服务，均仅限于VIP会员在平台使用，任何以恶意破 解等非法手段将本平台提供的服务内容与平台分离的行为，均不属于本协议中约定的本平台提供的服务。由此引起的一切法律后果由行为人负责，与本平台无关，且本平台将依法追究行为人的法律责任。</p>\
                    <p>2.4 您明确了解并同意，本平台VIP会员的付费方式为代收费运营商托收的付款方式，您通过此种付费方式付费可能存在一定的商业风险，包括但不限于不法分子利用您账户或银行卡等 有价卡等进行违法活动，该等风险均会给您造成相应的经济损失。您应自行承担向侵权方追 究侵权责任和追究责任不能的后果。</p>\
                    <p>2.5 您应自行负责妥善且正确地保管、使用、维护您在本平台申请取得的账户、账户信息及 账户密码。您应对您账户信息和账户密码采取必要和有效的保密措施。非本平台原因致使您 账户密码泄露以及因您保管、使用、维护不当造成损失的，本平台无须承担与此有关的任何 责任。</p>\
                    <p>2.6 本平台不对您因第三方的行为或不作为造成的损失承担任何责任，包括但不限于支付服 务和网络接入服务、任意第三方的侵权行为或其他违法违规行为。</p>\
                    <p>第3条 VIP会员申请及注册</p>\
                    <p>3.1 在成为本平台VIP会员之前，您必须先根据真实、准确信息注册成为本平台用户。用户 所填写的内容与个人资料必须真实有效，否则本平台有权拒绝其申请或撤销您的VIP会员资 格，并不予任何赔偿或退还VIP会员服务费。VIP会员个人资料发生变化的，您应及时修改 注册的个人资料，否则由此造成的VIP会员权利不能全面有效行使或无法获取本平台最新通 知的损失和责任均由VIP会员自行承担，且本平台有权因此取消您的VIP会员资格，并不予 退还VIP会员服务费或支付其他任何形式的任何赔偿。</p>\
                    <p>3.2 用户可通过各种已有和今后本平台指定的新增的支付渠道成为本平台VIP会员，包括但 不限于：通过网银支付、手机支付或第三方支付等现有或今后本平台指定的方式成为VIP会 员（具体支付渠道仍以本平台实际接受或说明为准）。只有当用户根据页面提示确认愿意并 成功支付了会员服务费（支付成功后不可转让或退订）以及完成了成为本平台会员的其他所 有程序，且通过本平台审核后，用户方可享受本VIP会员服务。如您拒绝支付该等费用或不 同意本协议任一条款或不同意成为本平台会员的其他任一程序的，则无法成为VIP会员及享 受相关VIP会员服务。在用户使用具体某种方式成为VIP会员时，须阅读并确认接受相关的 服务条款和使用规则。</p>\
                    <p>本平台在此声明：本平台从未授权任何第三方单位或个人销售、转让本平台VIP会员资格，任 何未经本平台明示授权而销售、转让本平台VIP会员资格的行为属于非法销售、非法转让， 本平台有权追究其法律责任。同时，对于如何成为本平台VIP会员，本平台将在相关页面上为 用户做出每一步的明确操作提示，用户可按相关提示进行注册操作。</p>\
                    <p>3.3 您不得通过以下任何方式为自己或他人（申请）开通本平台VIP会员，否则，本平台有权 不经通知拒绝申请或撤销相关用户的VIP会员资格，并不退还VIP会员服务费或支付其他任 何形式的任何赔偿，同时本平台有权追究相关的法律责任：</p>\
                    <p>3.3.1 未经过合法授权，以营利、经营等非个人使用的目的为自己或他人（申请）开通本平台VIP 服务；</p>\
                    <p>3.3.2 通过任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序、软件方式非法（申 请）开通本平台VIP会员；</p>\
                    <p>3.3.3 通过非本平台指定的其他方式（申请）开通本平台VIP会员；</p>\
                    <p>3.3.4 通过侵犯本平台或/及其关联公司或第三方合法权益，或其他不正当的，或违反诚实信 用原则的方式；</p>\
                    <p>3.3.5 通过其他违反相关法律、行政法规、国家政策等的方式。</p>\
                    <p>3.4 成为VIP会员后，VIP会员有权利选择不继续接受本平台的VIP会员服务，VIP会员可申 请取消VIP会员服务，但此取消行为将无法获得已交纳的VIP会员服务费的退还或其他任何形式的任何补偿/赔偿。</p>\
                    <p>3.5 本平台VIP会员账号所有权归本平台所有，VIP会员仅拥有本平台账号的有限使用权。</p>\
                    <p>3.6 本平台仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手 机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电 话费及上网费、为使用移动网而支付的手机费）均应由VIP会员自行负担。</p>\
                    <p>4.VIP会员服务和账号查询</p>\
                    <p>4.1 一旦您成为本平台VIP会员，即视为您认可该项服务标明之价格；成为本平台VIP会员后， 该项服务即时生效。</p>\
                    <p>4.2 VIP会员的增值服务资费标准以本平台网站上标注的详细资费标价为准，本平台有权基于 自身业务发展需要变更上述资费标准，但本平台会根据实际运营情况对不同阶段已经激活且 持续有效的VIP会员给予续费、升级方面的不同资费优惠，具体优惠政策以本平台在相关服 务页面公告的内容为准。</p>\
                    <p>4.3 您可以随时通过登录本平台VIP会员中心免费查询您的账号信息详情，包括已开通的服 务内容、服务期限、消费金额、交易状态等。</p>\
                    <p>4.4 目前因部分用户随意登录游戏，恶意占用游戏账号等行为造成本平台其他用户无法登录游戏。限制月会员每月任意登录游戏数量跟年会员每月任意登录游戏数量，游戏数量每月叠加使用。</p>\
                    <p>5.VIP会员权益及服务期限、收费标准</p>\
                    <p>5.1 本平台VIP会员权益，即本平台为VIP会员提供的专门服务，包括但不限于游戏试玩权限、 试玩积分打折、购买游戏打折服务等。本平台有权根据市场情况对会员权益、适用的用户设 备终端进行全部或部分的变更、取消、增加。具体内容以本平台平台“VIP特权”页面的说 明或实际提供为准。</p>\
                    <p>5.2 本平台VIP会员服务分为不同等级，不同会员等级之间的会员权益存在一定差异，具体以本平台页面的说明或实际提供为准。</p>\
                    <p>5.3 VIP会员服务提供的视频等服务都有固定的使用等会员服务期限，您一旦成为VIP会员即 视为认可它的使用等服务期限。如您希望享受会员服务连续采购和自动扣费的服务，您可以 根据操作指引向本平台提出申请，并在仔细阅读《会员自动续费服务协议》后决定是 否继续申请，详细内容请见页面显示的《会员自动续费服务协议》。</p>\
                    <p>5.4 您在购买本平台VIP会员后可享受的全部权益以本平台官方渠道公布的会员权益为准， 本平台有权基于本平台自身业务发展需要变更全部或部分会员权益。就前述权益调整本平台将在相应服务页面进行通知或公告，您也可通过本平台官方网站或移动端查询最新的会员权 益内容。同时本平台也鼓励您定期查看本协议内容，以更好地保障您的权益。</p>\
                    <p>5.5 VIP会员服务的服务期限以会员自行选择并支付相应会员费用的期限为准。会员服务的服 务期限到期后，本平台将停止继续向您提供VIP会员服务。</p>\
                    <p>5.6 本平台致力于不断改善自身服务，力求为您提供愈加优质、性价比更高的产品与服务。本平台VIP会员服务的收费标准由本平台根据自身运营策略独立决定（包括不同VIP会员类型、 会员等级、托管的运营商的不同收费标准、后续可能的资费调整等），并在会员服务支付页 面向您显示。您同意您继续操作的行为（包括但不限于点击同意、支付行为、继续使用会员 服务的行为等），即视为您知悉并同意该收费标准</p>\
                    <p>6.VIP会员的权利及限制</p>\
                    <p>6.1 在VIP会员有效期内，VIP会员可优先参加由本平台组织的活动并享有由本平台提供的 各项优惠及增值服务，同时VIP会员亦有权利不参加本平台组织的任何活动。</p>\
                    <p>6.2 VIP会员服务仅限于申请账号自行使用；VIP会员服务期内不能在本平台账号之间转移， 禁止赠与、借用、租用、转让或售卖。否则本平台有权在未经通知的情况下取消转让账号、 受让账号的VIP会员服务资格，由此带来的损失由VIP会员自行承担。</p>\
                    <p>6.3 您成为VIP会员后，可能会由于您使用的软件版本、设备、操作系统等不同以及其他第 三方原因等导致实际可使用的具体权益或服务有差别，由此可能给您带来的不便，您表示理 解且不予追究或者豁免本平台的相关责任。本平台建议您可以通过升级应用程序或操作系统 版本、更换使用设备等方式来尝试解决，或者直接通过本协议文末的联系方式与本平台联系 进行解决。</p>\
                    <p>6.4 用户不得以盗窃、利用系统漏洞等非法途径以及在未获本平台授权的非法销售、转让本平台VIP会员的网站或其他渠道上获取或购买VIP会员服务资格，否则本平台有权取消VIP 会员的服务资格。由此引发的问题由用户自行承担，本平台不负任何责任。</p>\
                    <p>6.5 用户使用VIP会员特权期间，使用外挂或其他恶意毁坏游戏账号等行为，由此带来的损 失由VIP会员自行承担，一经发现，本平台有权取消其VIP会员资格，并不予任何赔偿或退 还任何VIP会员服务费。</p>\
                    <p>6.6 被取消VIP会员资格的VIP会员，不能再参加由本平台组织的活动并不可再享有由本平台提供的各项优惠及增值服务，即不再享有VIP会员权利。</p>\
                    <p>6.7 本平台提供的VIP会员服务内容、VIP会员不得用于商业、盈利或者其他侵犯本平台或/ 及其关联公司合法权益（无论其是否是收费的）等用途，只可供个人使用。</p>\
                    <p>6.8 如VIP会员出现任何违反国家法律法规或监管政策的、违反本协议或本平台相关使用 规则的行为的或本平台认为VIP会员行为有损本平台或/及其关联公司的声誉、利益的，本平台有权独立决定单独或同时采取以下一项或多项措施进行处理：</p>\
                    <p>6.8.1 无需通知而中断或终止部分或全部VIP会员权益或服务，以及取消本平台VIP会员账户 和使用权限，并不予任何赔偿或退还任何VIP会员服务费；</p>\
                    <p>6.8.2如VIP会员的违约行为使本平台或/及其关联公司遭受损失的（包括但不限于直接经济 损失、商誉损失及对外支付的赔偿金、和解费、律师费、诉讼费等间接经济损失）的，会员 应当承担全部损失赔偿责任并在本平台要求的时限内支付费用完毕。</p>\
                    <p>6.9 VIP会员在使用本平台所提供的服务时，如遭受任何人身或财产的损失、损害或伤害， 除法律规定的责任外，不论原因如何，本平台或/及其关联公司均不负责任。</p>\
                    <p>6.10 VIP会员承诺在任何情况下均不得发表、行使任何不利于本平台或/及其关联公司的言论 和行为，包括但不限于通过微信、微博、采访、稿件等任何渠道及方式对本平台或/及其关 联公司品牌、声誉造成不利影响。</p>\
                    <p>6.11 成为VIP会员后，VIP会员有权利不继续接受本平台提供的会员服务。VIP会员可向本平台申请取消会员服务，但VIP会员知悉并同意对此无法获得会员服务费的退还。</p>\
                    <p>6.12 如在本平台平台展示了本平台VIP会员权益以外的其他付费内容的，经过本平台VIP会员 同意后，由对应的其他会员体系为您提供相应服务并收取服务费用。</p>\
                    <p>7.协议的变更</p>\
                    <p>7.1 本平台依据本协议的约定变更服务内容、服务方式以及本协议内容，如果VIP会员不同 意本平台的以上任何变更，有权选择取消并停止使用已经获取的对应的全部或部分服务；如 果在本平台进行上述变更且变更生效后，VIP会员仍继续使用本平台提供的上述服务，则视 为VIP会员已经接受本平台的上述调整的全部内容。</p>\
                    <p>7.2 本平台对本协议一旦进行修改，将在页面上或其他适当的位置公示修改的内容。一经公 示即视为已成功通知各VIP会员。</p>\
                    <p>7.3 VIP会员如果不同意条款的修改，可主动向本平台提出终止VIP会员服务，但本平台不退 还自开通VIP会员服务至实际终止VIP会员服务所在月的已享受VIP会员服务的部分费用； 如果VIP会员继续享用VIP会员服务，则视为VIP会员已经接受条款的修改。</p>\
                    <p>8.服务的中断和终止</p>\
                    <p>8.1 因发生本平台不可抗拒的事由，如政府行为、不可抗力（鉴于互联网之特殊性质，不可 抗力亦包括黑客攻击、电信部门技术调整导致之重大影响、因政府管制而造成之暂时关闭、 病毒侵袭等影响互联网正常运行之情形），导致VIP会员服务无法继续，本平台会以最快的 速度通知VIP会员，但不承担由此对VIP会员造成的任何损失并不退还VIP会员服务费。</p>\
                    <p>8.2 无论因何原因导致VIP会员服务的中断或终止，本平台有权进行如下处理：</p>\
                    <p>8.2.1 除法律法规另有规定外，本平台已收取的费用不予退还；</p>\
                    <p>8.2.2 用户已经产生但未使用的VIP会员权益自动清除且不折现；</p>\
                    <p>8.2.3 如因用户违约导致VIP会员服务中断或终止的，本平台有权视情况要求用户承担相应 的违约责任。</p>\
                    <p>8.3 本平台未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单一或 部分行使其在本协议项下的任何权利并不排斥其任何其它权利的行使。本平台随时有权要求 您继续履行义务并承担相应的违约责任。</p>\
                    <p>9.账号注销</p>\
                    <p>9.1除本平台按照本协议规定及相关法律法规注销用户的会员账号外，您可以通过在线申请 注销或联系本平台的客服或通过其他本平台公示的方式注销会员账号。账号一旦注销，您将 无法继续使用本平台或/及其关联公司的所有产品与服务，并放弃在使用本平台或/及其关联 公司的产品/服务期间已经产生但未消耗完毕的用户权益（包括但不限于会员权益）以及未 来的预期利益。</p>\
                    <p>9.2您的账号一旦注销，您曾通过该账号使用的本平台或/及其关联公司的产品与服务下的 所有内容、信息、数据、记录将会被删除或匿名化处理；除法律法规另有规定，您也无法再 检索、访问、获取、继续使用和找回，也无权要求本平台找回。</p>\
                    <p>10.未成年人条款和隐私政策</p>\
                    <p>10.1 本平台非常注重未成年人的保护。若用户未满18周岁，则为未成年人，应在监护人监 护、指导下阅读本协议和使用本VIP会员服务。</p>\
                    <p>10.2 监护人应指导子女上网应该注意的安全问题，防患于未然。若监护人同意未成年人使 用本平台提供的本VIP会员服务，必须以监护人名义申请消费，并对未成年人使用本会员服 务进行正确引导、监督。未成年人行使和履行本协议项下的权利和义务即视为已获得了监护 人的认可。</p>\
                    <p>10.3 本平台提醒未成年人用户在使用本会员服务时，要善于网上学习，认清网络世界与现实 世界的区别，避免沉迷于网络，影响日常的学习生活。</p>\
                    <p>11.通知为便于您获知本平台相关信息，您同意本平台有权通过网页公示、页面提示、弹窗、消息通 知、公众号通知、用户预留联系方式（手机短信、电子邮件等）等方式进行通知，该通知自 本平台发送之日视为已送达用户。如多种通知方式并存的，则送达时间以上述方式中最早发 送之时为准。</p>\
                    <p>12.法律的适用和管辖</p>\
                    <p>12.1 本服务条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，本服务条 款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。</p>\
                    <p>12.2 如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，任何一 方均可向北京市海淀区人民法院提起诉讼。</p>\
                    <p>13.其他</p>\
                    <p>13.1 如您对本《本平台VIP会员服务协议》或使用本平台的VIP会员服务相关的事宜有任何 问题（包括问题咨询、投诉等），请通过功能页面的用户反馈入口或在线客服，与本平台联系，本平台会在收到您的意见、建议后尽快向您回复。</p>\
                    <p>13.2 本协议的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。</p>'

var releaseImg = 'https://static.steambig.com/game_release/'
var DefaultFoot = '<div id="default_footer">\
                        <p>声明：本站不涉及游戏发行，推广及代理！</p>\
                        <span class="cef_version">版本号:</span>\
                        <span >开发者名称：酷助手</span>\
                        <a class="btns" href="http://yxgj.rzxyxx.cn/js/agreement.html target="_blank">用户协议</a>\
                        <a class="btns" href="http://yxgj.rzxyxx.cn/js/privacy.html target="_blank">隐私协议</a>\
                    </div>'
var commonFoot = '<div id="common_foot_div">\
                    <div class="w1024">\
                        <div class="box1">\
                            <div class="header">关于我们</div>\
                            <div>\
                                <p>平台服务协议</p>\
                                <p>客服中心</p>\
                                <p>工作机会</p>\
                                <p>举报投诉</p>\
                                <p>加入我们</p>\
                                <p>公司简介</p>\
                                <p>退款须知</p>\
                            </div>\
                        </div>\
                        <div class="box2">\
                            <div class="header">支付方式</div>\
                            <div>\
                                <img src="'+releaseImg+'pay_1.png" alt="">\
                                <img src="'+releaseImg+'pay_2.png" alt="">\
                                <img src="'+releaseImg+'pay_3.png" alt="">\
                                <img src="'+releaseImg+'pay_4.png" alt="">\
                                <img src="'+releaseImg+'pay_5.png" alt="">\
                            </div>\
                        </div>\
                        <div class="box3 box1">\
                            <div class="header">更多信息</div>\
                            <div>\
                                <p>广告联盟·游戏主播/up主</p>\
                                <p>开发商资源合作</p>\
                                <p>广告联盟·游戏论坛</p>\
                                <p>游戏博客</p>\
                            </div>\
                        </div>\
                    </div>\
                    <div class="w1024 footer">\
                        <a>'+(location.host == 'steamnew.com.htm'/*tpa=http://yxgj.xnzlt.cn/js/steamnew.com*/ ? "武汉寒冰科技有限公司 鄂ICP备2021020041号-1 Copyright © 2021 武汉寒冰科技版权所有" : "武汉泽拉特科技有限公司  Copyright © 2021版权所有")+'</a>\
                    </div>\
                    '+DefaultFoot+'\
                </div>' 

var CommonHead = '<div id="common_head">\
                    <div class="head_main">\
                        <img src="svg/steam_1.svg"/*tpa=http://yxgj.rzxyxx.cn/js/svg/steam_1.svg*/ class="img-responsive logo" alt="">\
                        <div>\
                            <img src="svg/steambig.svg"/*tpa=http://yxgj.rzxyxx.cn/js/svg/steambig.svg*/ class="img-responsive steambig steam_logo" alt="">\
                            <p>正版游戏体验特权</p>\
                        </div>\
                        <div class="placeholder"></div>\
                        <a class="down DownCEF" data-agl-cvt=6 id="360download_big">安装客户端</a>\
                    </div>\
                </div>'

